import Image from 'next/image';
import React from 'react';
import BBCard from '@/base_blocks/src/bbcard';
import BBLink from '@/base_blocks/src/bblink';
import BBText from '@/base_blocks/src/bbtext';
import CardTryNow from '@/components/cards/card_try_now';
import ContainerCentered from '@/components/containers/centered';
import LayoutLandingPage from '@/components/layouts/layout_landing_page';
import Logo from '@/images/logo-default.png';
import styles from '@/pages/styles.module.scss';
import type { NextPage } from 'next';
import Routes from 'routes';

const HomePage: NextPage | null = () => {
  return (
    <LayoutLandingPage title="Home" showHeader={false}>
      <ContainerCentered className={styles.containerTitleCentered}>
        <div className={styles.containerTitle}>
          {/* eslint-disable-next-line */}
          <Image src={Logo.src} alt="Piccy Bank Logo" height={100} width={120} />
          <BBText size="xxxlarge" color="primary" className={styles.textHeadliner}>
            Piccy Bank
          </BBText>
        </div>
        <BBText size="xxlarge" color="grey_dark">
          Unlock <i>your best</i> online content and data.
        </BBText>
      </ContainerCentered>

      <ContainerCentered color="var(--primary-light-color)" className={styles.containerTheProblem}>
        <BBText size="xxlarge">The Problem 🤔</BBText>
        <BBText size="large">Struggling to find training data?</BBText>
        <BBText size="large">Building a research report?</BBText>
        <BBText size="large">Or just collecting for fun?</BBText>
        <BBText size="large" bold>
          We&apos;ve got your back. 🍻
        </BBText>
        <hr />
        <BBText size="xlarge" bold>
          In today&apos;s digital chaos, sorting through information is a headache. 🤕
        </BBText>
        <BBText size="large">Keeping track of everything? A nightmare.</BBText>
        <BBText size="large">That&apos;s where we come in.</BBText>
      </ContainerCentered>

      <ContainerCentered>
        <BBCard className={styles.cardDoYouDealWith}>
          <BBCard.Header>
            <BBText size="xxlarge" bold>
              Do you deal with:
            </BBText>
          </BBCard.Header>
          <BBCard.Body className={styles.cardBodyDoYouDealWith}>
            <ul>
              <li>
                <BBText size="large">Feeling overwhelmed by information overload? 📚</BBText>
              </li>
              <li>
                <BBText size="large">Struggling to keep your online content organized? 🗄️</BBText>
              </li>
              <li>
                <BBText size="large">Wasting time searching for and sharing content efficiently? 🚀</BBText>
              </li>
            </ul>
            <BBText size="xlarge" bold>
              Try Piccy Bank - <i>the</i> solution!
            </BBText>
          </BBCard.Body>
          <BBCard.Footer>
            <BBText size="large">
              Learn more about our ideas and goals{' '}
              <BBLink asSpan href={Routes.routeAbout()} size="large">
                here
              </BBLink>
            </BBText>
          </BBCard.Footer>
        </BBCard>
      </ContainerCentered>

      <ContainerCentered className={styles.containerTechnology} color="var(--primary-light-color)">
        <BBText size="xxlarge">Cutting-Edge Technology 🌟</BBText>
        <BBText size="large">
          At Piccy Bank, we employ state-of-the-art techniques - including cutting-edge AI - to revolutionize content curation and
          organization.
        </BBText>
        <BBCard elevation="none" colorBorder="secondary">
          <BBCard.Body>
            <BBText size="xlarge">Advanced AI and Machine Learning 🤖</BBText>
            <BBText size="large">
              We utilize the latest and greatest in AI/ML to help make Piccy Bank the most powerful data tool around.
            </BBText>
            <BBText size="large">
              From content retrieval, to organization, and curation, rest assured you&apos;ve got the best in tech at your fingertips.
            </BBText>
          </BBCard.Body>
        </BBCard>

        <BBCard elevation="none" colorBorder="secondary">
          <BBCard.Body>
            <BBText size="xlarge">Dynamic Scraping Tactics 🕷️</BBText>
            <BBText size="large"> Our dynamic scraping tactics guarantee comprehensive data collection of the highest quality.</BBText>
            <BBText size="large">
              We enable you to find whatever information is most appropriate <i>for you</i> and your use case.
            </BBText>
          </BBCard.Body>
        </BBCard>

        <BBCard elevation="none" colorBorder="secondary">
          <BBCard.Body>
            <BBText size="xlarge">Efficient Data Processing 📀</BBText>
            <BBText size="large">
              Piccy Bank&apos;s systems are engineered to handle vast amounts of data, ensuring accessibility and organization at scale.
            </BBText>
          </BBCard.Body>
        </BBCard>
      </ContainerCentered>

      <ContainerCentered className={styles.containerFeatures} color="var(--secondary-light-color)">
        <BBText size="xxlarge" color="black">
          Features 🌟
        </BBText>
        <BBCard>
          <BBCard.Body>
            <BBText size="xlarge" bold>
              Dynamic Scraping 🕷️
            </BBText>
            <BBText size="large">
              Our dynamic scraping tactics guarantee comprehensive data collection of the highest quality from wherever you choose.
            </BBText>
          </BBCard.Body>
        </BBCard>
        <BBCard>
          <BBCard.Body>
            <BBText size="xlarge" bold>
              Easy Content Harvesting 🌾
            </BBText>
            <BBText size="large">Effortlessly gather online content with just a few clicks.</BBText>
          </BBCard.Body>
        </BBCard>
        <BBCard>
          <BBCard.Body>
            <BBText size="xlarge" bold>
              Intuitive UI/UX 🎨
            </BBText>
            <BBText size="large">Our user-friendly interface makes content management a breeze.</BBText>
          </BBCard.Body>
        </BBCard>
        <BBCard>
          <BBCard.Body>
            <BBText size="xlarge" bold>
              Secure Storage 🔒
            </BBText>
            <BBText size="large">Store your content securely with our advanced encryption.</BBText>
          </BBCard.Body>
        </BBCard>
        <BBCard>
          <BBCard.Body>
            <BBText size="xlarge" bold>
              Effortless Sharing 🌐
            </BBText>
            <BBText size="large">Share your stored content seamlessly with friends and colleagues.</BBText>
          </BBCard.Body>
        </BBCard>
      </ContainerCentered>
      <ContainerCentered>
        <CardTryNow />
      </ContainerCentered>
    </LayoutLandingPage>
  );
};

export default HomePage;
