import classnames from 'classnames';
import React from 'react';
import styles from '@/components/containers/centered/styles.module.scss';

/**
 * PROPS
 *
 * @param {React.ReactNode} children - Children to render
 * @param {string=} color - Color value for the container
 * @param {string=} height - Height value for the container
 * @param {string=} width - Width value for the container
 * @param {string=} className - Class name for the container
 */
interface IPropsContainerCentered {
  children: React.ReactNode;
  color?: string;
  height?: string;
  width?: string;
  className?: string;
}

/**
 * CONTAINER CENTERED
 * This container is used to center its children
 * It's simple and useful!
 */
export default function ContainerCentered(Props: IPropsContainerCentered): React.ReactElement {
  const { children, color, height = '100%', width = '100vw', className } = Props;

  /**
   * RENDER
   */
  return (
    <div className={classnames(styles.containerCentered, className)} style={{ backgroundColor: color, height, width }}>
      {children}
    </div>
  );
}
