import React from 'react';
import { Toaster } from 'react-hot-toast';
import FloatingButtonAuth from '@/components/buttons/floating_button_auth';
import FloatingButtonManagement from '@/components/buttons/floating_button_management';
import Footer from '@/components/footer';
import HeadHtml from '@/components/head_html';
import PageHeaderMain from '@/components/headers/page_header_main';
import styles from '@/components/layouts/layout_landing_page/styles.module.scss';
import NavbarMain from '@/components/navbars/navbar_main';

/**
 * PROPS
 *
 * @param {string} title - title of the page
 * @param {string=} url - url of the page
 * @param {React.ReactNode=} children - children components
 * @param {boolean=} showHeader - show header
 */
interface IPropsLayoutLandingPage {
  title: string;
  url?: string;
  children?: React.ReactNode;
  showHeader?: boolean;
}

/**
 * LAYOUT LANDING PAGE
 */
export default function LayoutLandingPage(Props: IPropsLayoutLandingPage): React.ReactElement {
  const { title, children, url, showHeader = true } = Props;

  const getUrl = () => {
    if (url) return url;
    return title.at(-1) === '/' ? title.toLowerCase() : title.toLowerCase() + '/';
  };

  // This section is to help render some components via the bootstrapper
  // feel free to simplify this post project creation
  let footer = <Footer />;
  let buttonFloating = (
    <>
      <FloatingButtonManagement />
      <FloatingButtonAuth />
    </>
  );
  const mainContent = (
    <>
      <div className={styles.mainContainer}>
        {showHeader && <PageHeaderMain textHeader={title} />}
        {children}
      </div>
      {buttonFloating}
      {footer}
    </>
  );

  /**
   * RENDER
   */
  return (
    <div>
      <HeadHtml title={title} url={getUrl()} />
      <Toaster />
      <NavbarMain mainContent={mainContent} />
    </div>
  );
}
