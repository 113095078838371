import { useRouter } from 'next/router';
import React from 'react';
import { MdCelebration } from 'react-icons/md';
import { useRecoilState } from 'recoil';
import BBButton from '@/base_blocks/src/bbbutton';
import BBCard from '@/base_blocks/src/bbcard';
import BBText from '@/base_blocks/src/bbtext';
import styles from '@/components/cards/card_try_now/styles.module.scss';
import { SIZE_ICON_XL } from '@/constants/sizes';
import { stateUser } from '@/states/auth';
import Routes from 'routes';

/**
 * CARD TRY NOW
 */
export default function CardTryNow(): React.ReactElement {
  // recoil
  const [user] = useRecoilState(stateUser);

  const router = useRouter();

  const onClick = (): void => {
    if (user) {
      void router.push(Routes.routeUserDashboard(user.id));
    } else {
      void router.push(Routes.routeSignup());
    }
  };

  /**
   * RENDER
   */
  return (
    <BBCard className={styles.cardTryNow}>
      <BBCard.Body>
        <BBText size="xxlarge" color="primary">
          Interested? 👀
        </BBText>
        <BBText size="xlarge">Try Piccy Bank Now!</BBText>
        <hr />
        <BBButton
          size="lg"
          variant="primary"
          text="Try Now"
          icon={{ icon: <MdCelebration size={SIZE_ICON_XL} />, align: 'right' }}
          onClick={onClick}
        />
      </BBCard.Body>
    </BBCard>
  );
}
